import { ICountResponse, IServiceCount, EServiceNames } from '~/types/myaccount'

export const serviceDictionary = {
  OrderCount: EServiceNames.Orders,
  EMSBackorderEnquiryCount: EServiceNames.BackOrders,
  EMSEnquiryHistoryCount: EServiceNames.Enquiries,
  EMSTestDriveEnquiryCount: EServiceNames.TestDrives,
  FavoriteVehicleCount: EServiceNames.Shortlist,
  RecentVehicleCount: EServiceNames.RecentlyViewed,
  SavedSearchCount: EServiceNames.SavedSearch,
  ValuationCount: EServiceNames.Valuations
}

export const formatCount = (
  countRes: ICountResponse | null
): Partial<IServiceCount> => {
  const response: Partial<IServiceCount> = {
    [EServiceNames.Orders]: 0,
    [EServiceNames.BackOrders]: 0,
    [EServiceNames.Enquiries]: 0,
    [EServiceNames.TestDrives]: 0,
    [EServiceNames.Shortlist]: 0,
    [EServiceNames.RecentlyViewed]: 0,
    [EServiceNames.SavedSearch]: 0,
    [EServiceNames.Valuations]: 0
  }

  if (!countRes) return response

  Object.entries(serviceDictionary).forEach(([key, value]) => {
    if (value === EServiceNames.BackOrders) {
      response[value] =
        countRes.EMSBackorderEnquiryCount + countRes.BackOrderCount
      return
    }

    response[value] = countRes[key as keyof ICountResponse]
  })

  return response
}
